import React from "react";
import InformationCard from "./InformationCard";
import { faHeartPulse, faTruckMedical, faTooth } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";

function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>What We Do</span>
        </h3>
        <p className="info-description">
          We bring healthcare to your convenience, offering a comprehensive
          range of on-demand medical services tailored to your needs. Our
          platform allows you to connect with experienced online doctors who
          provide expert medical advice, issue online prescriptions, and offer
          quick refills whenever you require them.
        </p>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="Emergency Care"
          description="Our Emergency Care service is designed to be your reliable support
            in critical situations. Whether it's a sudden illness, injury, or
            any medical concern that requires immediate attention, our team of
            dedicated healthcare professionals is available 24/7 to provide
            prompt and efficient care."
          icon={faTruckMedical}
        />

        <InformationCard
          title="Orthopedic Care"
          description="At Aadhithya Medical Centre, we specialize in comprehensive orthopedic care, addressing a spectrum of musculoskeletal issues. From precise diagnostics to advanced surgical interventions and rehabilitative therapies, our expert team is committed to restoring mobility and enhancing the quality of life."
          icon={faHeartPulse}
        />

        <InformationCard
          title="Orthopedic Surgery"
          description="At Aadhithya Medical Centre, our orthopedic surgeries blend precision and expertise to bring relief to musculoskeletal conditions. From joint replacements and arthroscopic procedures to corrective surgeries, our skilled orthopedic surgeons employ state-of-the-art techniques for optimal outcomes. "
          icon={faTooth}
        />
      </div>
    </div>
  );
}

export default Info;

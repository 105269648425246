export const customerReviews = [
    {
      "name": "Dinesh",
      "location": "Chennai, Tamil Nadu",
      "message": "Aadhithya Medical Centre transformed my healthcare experience! The online consultations were so convenient, and the doctors were knowledgeable and caring."
    },
    {
      "name": "Elavarasan",
      "location": "Coimbatore, Tamil Nadu",
      "message": "I found the perfect specialist for my condition through Aadhithya Medical Centre. The personalized treatment plan made all the difference. Thank you for prioritizing my health!"
    },
    {
      "name": "Prakash",
      "location": "Tanjore, Tamil Nadu",
      "message": "Booking appointments was a breeze, and the service exceeded my expectations. The doctors truly listen and provide effective solutions. 5 stars!"
    },
    {
      "name": "RamKumar",
      "location": "Kumbakonam, Tamil Nadu",
      "message": "Aadhithya Medical Centre is a game-changer! The emergency care saved me during a critical situation. Grateful for their quick and efficient support."
    },
    {
      "name": "Sabari",
      "location": "Mannargudi, Tamil Nadu",
      "message": "I used to dread dental visits, but Aadhithya Medical Centre made it a pleasant experience. The dentist was gentle and professional. I'll definitely be back!"
    },
    {
      "name": "Mahendran",
      "location": "Tiruvarur, Tamil Nadu",
      "message": "Finally, a healthcare platform that puts patients first! The heart disease service provided comprehensive care and regular follow-ups. Thank you, Aadhithya Medical Centre!"
    },
    
];

import React from "react";
import DoctorCard from "./DoctorCard";
import profile1 from "../Assets/profile-1.jpg";

import "../Styles/Doctors.css";

function Doctors() {
  return (
    <div className="doctor-section" id="doctors">
      <div className="dt-title-content">
        <h3 className="dt-title">
          <span>Meet Our Doctor</span>
        </h3>

        <p className="dt-description">
          Meet our exceptional specialist doctor, dedicated to
          providing top-notch healthcare services at Orthopedic. Senior General  and Laprscopic Surgeon with 30 years of Experience. Former Professor and HOD of Department of General Surgery. DEAN I/C  THANJAVUR MEDICAL COLLEGE .Medical Director  of Aadhithya Medical Centre, Cauvery Nagar,Thanjavur.DEDICATED IN Diabetic  foot care in offordable cost .
        </p>
      </div>

      <div className="dt-cards-content">
        <DoctorCard
          img={profile1}
          name="DR.M.ELANGOVAN"
          title="Senior General Surgeon"
          stars="4.9"
          reviews="1800"
        />
        </div>
    </div>
  );
}

export default Doctors;

import React from "react";
import Doctor from "../Assets/doctor-group.jpg";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <img src={Doctor} alt="Doctor Group" className="about-image1" />
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          <span>About Us</span>
        </h3>
        <p className="about-description">
        Welcome to Aadhithya Medical Centre, where compassionate care meets cutting-edge orthopedic expertise. At Aadhithya, we are dedicated to enhancing lives through specialized orthopedic treatments, providing a beacon of hope for those seeking relief from musculoskeletal issues.
        </p>

        <h4 className="about-text-title">Your Solutions</h4>

        <SolutionStep
          title="Our Commitment to Excellence"
          description="With a team of highly skilled orthopedic specialists, surgeons, and healthcare professionals, Aadhithya Medical Centre stands at the forefront of orthopedic innovation. We are committed to delivering personalized and comprehensive care that addresses a wide range of orthopedic conditions, from joint pain and fractures to sports injuries and beyond."
        />

        <SolutionStep
          title="Patient-Centric Approach"
          description="At the heart of our practice is a patient-centric philosophy. We understand that each individual's journey to orthopedic wellness is unique, and we tailor our treatments to meet your specific needs. From the moment you walk through our doors, you become a valued partner in your healthcare journey."
        />

        <SolutionStep
          title="Your Journey Starts Here"
          description="At Aadhithya Medical Centre, we are more than a medical facility; we are a partner in your journey to a healthier, more active life. Explore the possibilities of orthopedic excellence with us and experience the transformative impact of specialized care that puts you at the center of everything we do."
        />
      </div>
    </div>
  );
}

export default About;
